interface Tab {
  link: string
  title: string
  isActive: boolean
}

export const useNavigationStore = defineStore('navigationStore', () => {
  const navigationTabs = ref<Tab[]>([]);
  const activeBottom = ref(false);

  const currentActiveTab = computed(() => {
    const tab = navigationTabs.value.find((tab) => tab.isActive === true);
    if (tab) {
      return tab;
    }
    if (activeBottom.value) {
      return navigationTabs.value[navigationTabs.value.length - 1];
    }
    return navigationTabs.value[0];

    // if (import.meta.client) {
    //   const currentPos = window.scrollY;
    //   const pageHeight = document.body.scrollHeight;

    //   return currentPos < pageHeight * 0.75 ? navigationTabs.value[0] : navigationTabs.value[navigationTabs.value.length - 1];
    // }
  });

  const cleanTabs = () => {
    navigationTabs.value = [];
  };
  return {
    navigationTabs,
    activeBottom,
    currentActiveTab,
    cleanTabs
  };
});
